import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  {
    path: '',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then( m => m.DashboardPageModule)
  },
  {
    path: 'order',
    loadChildren: () => import('./order/order.module').then( m => m.OrderPageModule)
  },
  {
    path: 'order/:id/:type',
    loadChildren: () => import('./order/order.module').then( m => m.OrderPageModule)
  },
  {
    path: 'fulfill-order',
    loadChildren: () => import('./fulfill-order/fulfill-order.module').then( m => m.FulfillOrderPageModule)
  },
  {
    path: 'fulfill-order/:id',
    loadChildren: () => import('./fulfill-order/fulfill-order.module').then( m => m.FulfillOrderPageModule)
  },
  {
    path: 'edit-fulfill-order',
    loadChildren: () => import('./edit-fulfill-order/edit-fulfill-order.module').then( m => m.EditFulfillOrderPageModule)
  },
  {
    path: 'order-done',
    loadChildren: () => import('./order-done/order-done.module').then( m => m.OrderDonePageModule)
  },
  
  {
    path: 'edit-menu/:id',
    loadChildren: () => import('./edit-menu/edit-menu.module').then( m => m.EditMenuPageModule)
  },
  {
    path: 'menu',
    loadChildren: () => import('./menu/menu.module').then( m => m.MenuPageModule)
  },
  {
    path: 'menu-setting/:id',
    loadChildren: () => import('./menu-setting/menu-setting.module').then( m => m.MenuSettingPageModule)
  },
  {
    path: 'menu-setting',
    loadChildren: () => import('./menu-setting/menu-setting.module').then( m => m.MenuSettingPageModule)
  },
  {
    path: 'setting',
    loadChildren: () => import('./setting/setting.module').then( m => m.SettingPageModule)
  }


];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
